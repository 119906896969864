import {
  AdvertProposalsFilter,
  AdvertsFilter,
  AnimalsFilter,
  ContractsFilter,
} from "@/types";

import {
  AdvertProposalStatus,
  AdvertStates,
  AdvertTypes,
  SaleTypes,
} from "./constants";

export const defaultAnimalsFilter: AnimalsFilter = Object.freeze({
  filter: null,
  ranchId: null,
  state: null,
  stateGroup: null,
  crotal: null,
  contractNumber: null,
  breed: null,
  sex: null,
  age: null,
  cea: null,
  igp: null,
  ageFrom: null,
  ageTo: null,
  contractId: null,
  slaughterHouseId: null,
  deliveryCenterId: null,
  slaughterDateFrom: null,
  slaughterDateTo: null,
  deliveryDateFrom: null,
  deliveryDateTo: null,
});

export const defaultContractsFilter: ContractsFilter = Object.freeze({
  filter: "",
  ranchId: null,
  contractNumber: null,
  contractName: null,
  operatorName: null,
  state: null,
  numberOfAnimals: null,
  numberOfAnimalsFrom: null,
  numberOfAnimalsTo: null,
  pendingAnimals: null,
  pendingAnimalsFrom: null,
  pendingAnimalsTo: null,
  pendingPetitions: null,
  pendingPetitionsFrom: null,
  pendingPetitionsTo: null,
  ranchName: null,
  aica: null,
  cea: null,
  creationDateFrom: null,
  creationDateTo: null,
  endDateFrom: null,
  endDateTo: null,
});

export const defaultAdvertFilters: AdvertsFilter = Object.freeze({
  filter: "",
  advertType: AdvertTypes.ALL,
  saleType: SaleTypes.ALL_NULL,
  advertCategory: null,
  status: AdvertStates.ALL,
  userId: null,
  priceFrom: null,
  priceTo: null,
  priceType: null,
  quantityFrom: null,
  quantityTo: null,
  sex: null,
  breed: null,
  igp: null,
  ageFrom: null,
  ageTo: null,
  hasProposals: null,
  hasOpenProposals: null,
  province: null,
});

export const defaultAdvertProposalFilters: AdvertProposalsFilter =
  Object.freeze({
    filter: "",
    status: AdvertProposalStatus.ALL,
    statuses: null,
    sent: 1,
    template: null,
    rate: null,
    priceType: null,
    priceFrom: null,
    priceTo: null,
    sex: null,
    breed: null,
    igp: null,
    ageFrom: null,
    ageTo: null,
    quantityFrom: null,
    quantityTo: null,
    creationDateFrom: null,
    creationDateTo: null,
  });
