import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";

import { notificationsApi } from "@/api/notifications";
import { useAuthStore } from "@/stores/AuthStore";

export function useNotificationsUnreadCount() {
  const authStore = useAuthStore();
  const { data: unreadCount, refetch: refetchUnreadCount } = useQuery({
    queryKey: ["notifications-unread-count"],
    queryFn: () =>
      notificationsApi.getUnreadCount().catch((error) => {
        console.error("Failed to fetch unread count:", error);
        return 0;
      }),
    refetchInterval: 30000,
    staleTime: 15000,
    refetchOnWindowFocus: true,
    enabled: computed(() => authStore.isLoggedIn),
  });

  return {
    unreadCount: computed(() => unreadCount.value ?? 0),
    hasUnread: computed(() => (unreadCount.value ?? 0) > 0),
    refetchUnreadCount,
  };
}
