import { RouteRecordRaw } from "vue-router";

const TabsOperador = () => import("../views/operador/TabsOperador.vue");
const AnimalesOperador = () => import("../views/operador/Animales.vue");
const ContratosOperador = () => import("../views/operador/Contratos.vue");
const ContratoOperador = () => import("../views/operador/Contrato.vue");
const PerfilOperador = () => import("../views/operador/Perfil.vue");
const FeriaVirtualOperador = () =>
  import("../views/feria_virtual/FeriaVirtual.vue");
const AdvertiserProfileOperator = () =>
  import("../views/feria_virtual/AdvertiserProfile.vue");
const MyAdvertProposals = () =>
  import("../views/feria_virtual/MyAdvertProposals.vue");
const AdvertProposalViewOperator = () =>
  import("../views/feria_virtual/AdvertProposalView.vue");

const TechniciansOperator = () =>
  import("../views/operador/technicians/TechniciansOperator.vue");
const TechniciansCrudOperator = () =>
  import("../views/operador/technicians/TechniciansCrud.vue");
const ConfiguracionOperador = () =>
  import("../views/operador/Configuracion.vue");
const NotificationsPage = () => import("../views/NotificationsPage.vue");
const Contact = () => import("../views/ContactPage.vue");
const Support = () => import("../views/SupportPage.vue");
const Rates = () => import("../views/rates/Rates.vue");
const RatesCrud = () => import("../views/rates/RatesCrud.vue");
const TemplatesView = () => import("../views/templates/Templates.vue");
const NewContract = () => import("../views/NewContract.vue");
const OfflineContracts = () => import("../views/offline/OfflineContracts.vue");
const NewOfflineContract = () =>
  import("../views/offline/NewOfflineContract.vue");
const OfflineContract = () => import("../views/offline/OfflineContract.vue");
const OfflineRanchers = () => import("../views/offline/OfflineRanchers.vue");
const OfflineRanchersCrud = () =>
  import("../views/offline/OfflineRanchersCrud.vue");
const AdvertView = () => import("../views/feria_virtual/AdvertView.vue");

const operadorRoutes: RouteRecordRaw[] = [
  {
    path: "/:lang/operador",
    component: TabsOperador,
    meta: { requiresAuth: true },
    children: [
      {
        path: "animales",
        name: "animales-operador",
        component: AnimalesOperador,
      },
      {
        path: "contratos",
        name: "contratos-operador",
        component: ContratosOperador,
      },
      {
        path: "contrato/:contractId",
        name: "contrato-operador",
        component: ContratoOperador,
        props: true,
      },
      {
        path: "contratos/nuevo",
        name: "nuevo-contrato-operador",
        component: NewContract,
      },
      {
        path: "contratos/offline",
        name: "offline-contracts-operator",
        component: OfflineContracts,
      },
      {
        path: "contratos/offline/new",
        name: "new-offline-contract-operator",
        component: NewOfflineContract,
      },
      {
        path: "contratos/offline/:contractId",
        name: "offline-contract-operator",
        props: (route) => ({
          contractId: route.params.contractId
            ? route.params.contractId
            : undefined,
        }),
        component: OfflineContract,
      },
      {
        path: "contratos/offline/ranchers",
        name: "offline-ranchers-operator",
        component: OfflineRanchers,
      },
      {
        path: "contratos/offline/ranchers/crud/:rancherId?",
        name: "offline-ranchers-crud-operator",
        props: (route) => ({
          rancherId: route.params.rancherId
            ? route.params.rancherId
            : undefined,
        }),
        component: OfflineRanchersCrud,
      },
      {
        path: "contratos/rates",
        name: "rates-operator",
        component: Rates,
      },
      {
        path: "contratos/rates/crud/:rateId?",
        name: "rates-crud-operator",
        props: (route) => ({
          rateId: route.params.rateId ? route.params.rateId : undefined,
        }),
        component: RatesCrud,
      },
      {
        path: "contratos/templates",
        name: "templates-operator",
        component: TemplatesView,
      },
      {
        path: "contratos/technicians",
        name: "technicians-operator",
        component: TechniciansOperator,
      },
      {
        path: "contratos/technicians/crud/:technicianId?",
        name: "technicians-crud-operator",
        props: (route) => ({
          technicianId: route.params.technicianId
            ? route.params.technicianId
            : undefined,
        }),
        component: TechniciansCrudOperator,
      },
      {
        path: "notifications",
        name: "notifications-operador",
        component: NotificationsPage,
      },
      {
        path: "perfil",
        name: "perfil-operador",
        component: PerfilOperador,
      },
      {
        path: "feria",
        name: "feria-virtual-operador",
        component: FeriaVirtualOperador,
      },
      {
        path: "feria/anuncio/:advertId",
        name: "vista-anuncio-operador",
        component: AdvertView,
        props: true,
      },
      {
        path: "feria/anunciante/:userId",
        name: "vista-anunciante-operador",
        component: AdvertiserProfileOperator,
        props: true,
      },
      {
        path: "feria/propuestas/mis-propuestas",
        name: "mis-propuestas-operador",
        component: MyAdvertProposals,
      },
      {
        path: "feria/propuestas/:advertProposalId",
        name: "vista-propuesta-operador",
        component: AdvertProposalViewOperator,
        props: true,
      },
      {
        path: "configuracion",
        name: "configuracion-operador",
        component: ConfiguracionOperador,
      },
      {
        path: "contact",
        name: "contact-operador",
        component: Contact,
      },
      {
        path: "support",
        name: "support-operador",
        component: Support,
      },
    ],
  },
];

export default operadorRoutes;
