/* eslint-disable custom-rules/max-lines */
export default {
  es: {
    btnGuardadoAnimal: "Animal guardado correctamente",
    btnGuardando: "Guardando...",
    btnGuardar_error: "Error al guardar",
    btnCacelar: "Cancelar",
    btnGuardar: "Guardar",
    btnAlta: "Alta",
    btnAceptar: "Aceptar",
    btnAcceder: "Acceder",
    btnCerra: "Cerrar",
    animales: "Animales",
    btnFirmar: "Firmar",
    btnFirmar_exito: "Activada firma",
    btnFirmar_error: "Error al actiavr la firma",
    noresultados: "No se han encontrado resultados...",
    btnTextIniciarFirma: "Iniciar firma",
    btnTextIniciarFirmaFB: "Proceso de firma iniciado",
    btnResetPass: "Resetear Contraseña",
    accionConExito: "Acción realizada con éxito",
    offlinealert:
      "Se ha detectado un error de red, por favor compruebe su conexión ",
    affirmation: {
      true: "Si",
      false: "No",
    },
    perfil: {
      msgIBANko:
        "El IBAN no es correcto, compruebe que no hay espacios en blanco y que los datos son correctos.",
      btnModificarPerfil_stage0: "Modificar",
      btnModificarPerfil_stage1: "Guardar",
      btnModificarPerfil_stage2: "Guardando ...",
      btnModificarPerfil_stage3: "Gurdado con éxito",
      btnModificarPerfil_stage4: "Error en el guardado",
      sin_numCuenta: "No tiene un número de cuenta asociado a su perfil",
      cancelar: "Cancelar",
      cea: "CEA",
      nombre_explotacion: "Nombre de la explotación",
      tipo_explotacion: "Tipo de Explotación",
      anuncio: "Anuncio",
      general: "Datos Generales",
      orientacion: "Orientación Productiva",
      igp: "IGP",
      asociacion: "Asociación de productores",
      direccion_explotacion: "Dirección de la explotación",
      localidad: "Localidad",
      provincia: "Provincia",
      codigo_postal: "Código postal",
      direccion: "Dirección",
      cif: "CIF",
      contacto: "Contacto",
      telefono: "Teléfono",
      email: "Email",
      web: "Web",
      visitar: "Visitar la web",
      anuncio_update_ok: "El anuncio se actualizó correctamente",
    },
    animales_section: {
      crotal: "Crotal",
      raza: "Raza",
      sexo: "Sexo",
      edad: "Edad",
      grafica_edad: "ANIMALES COMPRADOS NO SACRIFICADOS",
      baja_animal: "Baja animal",
      venta_animal: "Venta animal",
      en_venta: "Poner en venta",
      sacrificar: "Informar de sacrificio",
      quitar_venta: "Quitar de venta",
      baja_animal_texto:
        "Está a punto de dar de baja el animal, indique el motivo de la misma.",
      en_venta_texto: "Indique una valoración del animal",
      baja_button: "Confirmar baja",
      baja_ok: "El animal se ha dado de baja correctamente.",
      baja_error: "El animal no puede ser dado de baja desde el estado actual",
      calidad_carne: "Calidad del animal",
      tipo_venta: "Tipo de venta",
      venta_button: "Confirmar venta",
      venta_error:
        "El animal no puede ser puesto en venta desde el estado actual",
      venta_ok: "Ha modificado el estado del animal correctamente.",
      quitar_venta_error:
        "El animal no puede ser quitado de venta desde el estado actual",
      quitar_venta_ok: "Ha modificado el estado del animal correctamente.",
      anuncio: "anuncio",
      nombre_explotacion: "Nombre explotación",
      direccion_explotacion: "Dirección explotacion",
      explotacion: "Explotacion",
      fecha_nacimiento: "Fecha nacimiento",
      numero_contrato: "Número contrato",
      sacrificio: "Sacrificio animal",
      fecha_sacrificio: "Fecha de sacrificio",
      matadero: "Matadero",
      sacrificado: "Sacrificado",
      entidad_calificadora: "Entidad calificadora",
      peso_canal: "Peso en canal",
      calidad_carne_matadero: "Calidad del animal para el matadero",
      image: "Imagen del animal",
    },
    feria_virtual: {
      title: "Feria Virtual",
      map: {
        "info-window": {
          nombre_explotacion: "Nombre explotación",
          fecha_1_venta: "Fecha 1ª venta",
          anuncio: "Anuncio",
          numero_animales_venta: "Nº de animales en venta",
          media_animales_expotacion: "Media de animales",
          asociacion_productores: "Asociación de productores",
          igp: "IGP",
        },
        "detail-list": {
          general: "Datos generales",
          explotacion: "Explotación",
          animales_venta: "Animales en venta",
        },
      },
    },
    contratos_section: {
      contrato: "Contrato",
      animales: "Animales",
      informacion: "Información",
      documentos: "Documentos",
      animales_contrato: "Animales en contrato",
      evidencia_firma: "Evidencia de firma",
      acciones_contrato: "Acciones asociadas al contrato",
      recibo_aica: "Recibo AICA",
    },
    titulos_rutas: {
      login: "Acceso",
      "animales-operador": "Animales",
      "contratos-operador": "Contratos",
      "contrato-operador": "Contrato",
      "perfil-operador": "Perfil",
      "feria-virtual-operador": "Feria Virtual",
      "animales-ganadero": "Animales",
      "animales-ganadero-en-propiedad": "Animales en propiedad",
      "contratos-ganadero": "Contratos",
      "contrato-ganadero": "Contrato",
      "feria-virtual-technician": "Feria Virtual",
      "contratos-technician": "Contratos",
      "perfil-ganadero": "Perfil",
      "movimientos-ganadero": "Movimientos",
      "feria-virtual-ganadero": "Feria Virtual",
      "rates-operator": "Tarifas",
      "rates-crud-operator": "Crear Tarifa",
      "rates-ganadero": "Tarifas",
      "rates-crud-ganadero": "Crear Tarifa",
      "technicians-operator": "Técnicos",
      "perfil-technician": "Perfil",
      "nuevo-anuncio-ganadero": "Nuevo Anuncio",
      "vista-anuncio-ganadero": "Anuncio",
      "vista-anunciante-ganadero": "Anunciante",
      "vista-anunciante-operador": "Anunciante",
      "animal-ganadero": "Animal",
      "mis-anuncios-ganadero": "Mis Anuncios",
      "mis-anuncios-operador": "Mis Anuncios",
      "vista-mi-anuncio-ganadero": "Mi Anuncio",
      "vista-anuncio-operador": "Anuncio",
      "nuevo-anuncio-operador": "Nuevo Anuncio",
      "mis-propuestas-ganadero": "Mis Propuestas",
      "mis-propuestas-operador": "Mis Propuestas",
      "vista-propuesta-ganadero": "Propuesta",
      "vista-propuesta-operador": "Propuesta",
      "offline-ranchers-operator": "Agenda de Ganaderos",
      "offline-ranchers-crud-operator": "Crear Ganadero",
      "offline-contracts-operator": "Contratos offline",
      "offline-contract-operator": "Contrato offline",
      "offline-ranchers-rancher": "Agenda de Ganaderos",
      "offline-ranchers-crud-rancher": "Crear Ganadero",
      "offline-contracts-rancher": "Contratos offline",
      "offline-contract-rancher": "Contrato offline",
    },
    login: {
      error_credenciales:
        "Por favor compruebe que las credenciales son correctas",
      usuario: "Usuario...",
      email: "Introduzca su email",
      contrasenya: "Contraseña...",
      forgot_pass: "¿Ha olvidado su contraseña?",
    },
    register: {
      register: "Registrar",
      back: "volver",
    },
    reset_pass: {
      reset_ok_title: "La contraseña ha sido reseteada correctamente",
      reset_ok_detail:
        " Revise su correo electrónico para continuar con el proceso de restablecimiento de contraseña",
    },
    update_pass: {
      title: "Cambiar contraseña",
      pass: "Contraseña actual",
      new_pass: "Nueva contraseña",
      confirm_pass: "Confirmar contraseña",
      update: "Actualizar",
      update_ok: "La contraseña se ha actualizado correctamente",
    },
    aica_user: {
      title: "Modificar usuario AICA",
      username: "Usuario AICA",
      pass: "Contraseña AICA",
      confirm_pass: "Confirmar contraseña",
      create: "Modificar usuario AICA",
      create_ok: "Usuario AICA creado correctamente",
    },
    footer: "© 2025 Agrocontratación. Todos los derechos reservados",
    estado_contrato: {
      0: "estado desconocido",
      1: "nuevo",
      2: "propuesto al ganadero",
      3: "firmado por el ganadero",
      4: "firmado por ambas partes",
      5: "propuesto para finalizacion por ganadero",
      6: "propuesta de finalizacion aceptada por ganadero",
      7: "finalizado consensuadamente",
      8: "propuesto para finalizacion  por operador",
      9: "propuesta de finalizacion aceptada por operador",
      10: "rescindido",
      11: "rechazado",
      12: "en proceso de firma",
    },
    estado_animales: {
      0: "estado desconocido",
      1: "en explotacion",
      2: "en venta",
      3: "propuesto para contrato",
      4: "en contrato",
      5: "pendiente de salir de contrato",
      6: "sacrificado",
      7: "propuesto para sacrificio",
      8: "baja",
    },
    razas: {
      1: "Asturiana de los valles",
      2: "Asturiana de la montaña",
      3: "Conjunto mestizo",
      4: "Frisona",
      5: "Parda",
      6: "Parda de montaña",
      7: "Limusina",
    },
    codigos_error: {
      0: "Error en la operación",
      401: "Acceso denegado",
      500: "Error de conexión con el servidor",
      4041: "No existe ningún operador asociado al usuario: {username}",
      4042: "No existe ningún contrato asociado a este operador: {username}",
      4001: "El animal con identificador: animalIS no está asociado al operador:{username}",
      4043: "El contrato no está asociado a este operador: {username}",
      4044: "No existe ninguna explotación asociada al identificador proporcionado: explotacionId",
      4045: "No existe ningún técnico asociado al identificador proporcionado: tecnicoId",
      4046: "No existe ninguna plantilla asociada al identificador proporcionado: {id}",
      4002: "El contrato contractName no tiene ningún fichero asociado",
      4047: "No existe un matadero con un identificador igual al proporcionado: mataderoId",
      4003: "El operador: {username}  no es el dueño del contrato proporcionado: contractName",
      4004: "Los campos tipo_venta y valoracion deben estar informados en un animal para poder ponerlo a la venta",
      4005: "El ganadero: {username} no tiene asociada ninguna explotación en el sistema",
      4006: "La explotación proporcionada para el animal no pertenece al ganadero",
      4007: "Ya existe un animal con el crotal proporcionado: {crotal}",
      4048: "No existe ningún animal con el identificador proporcionado:{id}",
      4049: "No existe ningún animal con el identificador proporcionado: animaId  perteneciente al ganadero: {username}",
      40410:
        "No existe ningún contrato con el identificador proporcionado contractId asociado a las explotaciones de este ganadero",
      4008: "No existe ningún usuario con el ID asociado al operador para este contrato",
      4009: "No existe el operador asociado a este contrato",
      40010:
        "La explotación asociada al contrato: {contractName} no pertenece al ganadero",
      40411: "No existe ningún ganadero asociado al usuario: {username}",
      40012:
        "No existe ninguna explotación con el identificador proporcionado en el sistema para este ganadero",
      40013:
        "El identificador del ganadero asociado a la explotación no coincide con el identificador del ganadero que está realizando la petición",
      40014:
        "El identificador de la explotación asociado a la explotación en el cuerpo de la petición no coincide con el identificador de la explotación proporcionado en la URI de la petición",
      40015:
        "No se encontró ningún documento con signaturit documento ID: {id}",
      40016: "No se encontró ningún contrato asociado al fichero: {id}",
      40017:
        "El estado del contrato es inconsistente con la firma completada en signaturit: {contractState}",
      40412: "No existe ningún técnico asociado al usuario: {username}",
      40413:
        "No existe ningún contrato asociado a este matadero: {mataderoName}",
      40018: "El usuario: {username}  no tiene un matadero asociado",
      40019:
        "El animal con ID: {id} no pertenece a un contrato asociado al matadero",
      40414: "No existe ningún contrato asociado a este técnico: {tecnicoName}",
      40020: "El animal:{id} no está asociado al técnico: {tecnicoName}",
      40021:
        "No se puede pasar un animal de un estado: currentState a un estado: {state}",
      40022:
        "No se puede llevar a cabo la acción para un contrato en estado: {state}",
      40415:
        "No existe ningún contrato con el identificador proporcionado: {id}",
      40023:
        "El contrato ya tiene el número máximo de animales asignados: {maxNumber}",
      40416:
        "No se encontró ningún fichero en la base de datos para el ID:{id}",
      404113: "Por favor compruebe que el correo electrónico es correcto",
      409001: "La contraseña actual que ha introducido es incorrecta.",
      5001: "El reseteo de contraseña ha fallado",
    },
    dialogs: {
      rescindContract:
        'Esta acción implica que se "Rescinde" el contrato unilateralmente, eso podrá tener implicaciones jurídicas si la otra parte no está de acuerdo. Con esta acción el contrato se cerrará, y todos los animales que estuviesen pendientes de entrega se desasignarán automáticamente del contrato. ¿Esta de acuerdo en cursar esa petición?',
      rejectContract:
        'Esta acción implica que se "Rechaza" el contrato ¿Esta de acuerdo en cursar esa petición?',
      endContract:
        'Esta acción enviará a la otra parte una petición para "Finalizar" el contrato. Esto significa que si la otra parte acepta la petición no se podrán asignar mas animales a este contrato en el futuro. Los animales incluidos en el contrato que estén pendientes de entrega se regirán por las condiciones acordadas en el contrato. Esto requerirá la firma por ambas partes de la modificación del contrato. ¿Esta de acuerdo en cursar esa petición?',
      removeContract:
        "Esta acción implica eliminar el contrato seleccionado. ¿Esta de acuerdo en cursar esa petición?",
      gestionarSalidaDeAnimal:
        'Esta acción implica que se "Desasigna" el animal del contrato. Con esta acción se enviará una petición al operador para desasignar el animal del contrato. ¿Está de acuerdo en cursar esa petición?',
      btnCancel: "Cancelar",
      btnConfirm: "Confirmar",
      btnDiscard: "Descartar",
    },
  },
  en: {
    btnGuardadoAnimal: "Animal saved correctly",
    btnGuardando: "Saved...",
    btnCacelar: "Cancel",
    btnGuardar: "Save",
    btnAlta: "Registry animal",
    btnAceptar: "Acept",
    btnCerra: "Close",
    btnAcceder: "Access",
    animales: "Animals",
    noresultados: "No results...",
    btnTextIniciarFirma: "Start signature",
    btnTextIniciarFirmaFB: "Signature started, check your email",
    accionConExito: "Successful action",
    offlinealert:
      "Se ha detectado un error de red, por favor compruebe su conexión ",
    titulos_rutas: {
      login: "Login",
      "animales-operador": "Animals",
      "contratos-operador": "Contracts",
      "perfil-operador": "Profile",
      "animales-ganadero": "Animals",
      "contratos-ganadero": "Contracts",
      "perfil-ganadero": "Profile",
    },
    perfil: {
      msgIBANko:
        "El IBAN no es correcto, compruebe que no hay espacios en blanco y que los datos son correctos.",
      btnModificarPerfil_stage0: "Modificar",
      btnModificarPerfil_stage1: "Guardar",
      btnModificarPerfil_stage2: "Guardando ...",
      btnModificarPerfil_stage3: "Gurdado con éxito",
      btnModificarPerfil_stage4: "Error en el guardado",
      sin_numCuenta: "No tiene un número de cuenta asociado a su perfil",
      cancelar: "Cancelar",
      anuncio: "Anuncio",
      general: "Datos Generales",
      orientacion: "Orientación Productiva",
      igp: "IGP",
      asociacion: "Asociación de productores",
      localozacion: "Localización",
      localidad: "Localidad",
      contacto: "Contacto",
      telefono: "Teléfono",
      email: "Email",
      web: "Web",
      visitar: "Visitar la web",
      anuncio_update_ok: "El anuncio se actualizó correctamente",
    },
    animales_section: {
      crotal: "Crotal",
      raza: "Raza",
      sexo: "Sexo",
      edad: "Edad",
      grafica_edad: "ANIMALES COMPRADOS NO SACRIFICADOS",
      baja_animal: "Baja animal",
      venta_animal: "Venta animal",
      en_venta: "Poner en venta",
      sacrificar: "Informar de sacrificio",
      quitar_venta: "Quitar de venta",
      baja_animal_texto:
        "Está a punto de dar de baja el animal, indique el motivo de la misma.",
      en_venta_texto: "Indique una valoración del animal",
      baja_button: "Confirmar baja",
      baja_ok: "El animal se ha dado de baja correctamente.",
      baja_error: "El animal no puede ser dado de baja desde el estado actual",
      calidad_carne: "Calidad del animal",
      tipo_venta: "Tipo de venta",
      venta_button: "Confirmar venta",
      venta_error:
        "El animal no puede ser puesto en venta desde el estado actual",
      venta_ok: "Ha modificado el estado del animal correctamente.",
      quitar_venta_error:
        "El animal no puede ser quitado de venta desde el estado actual",
      quitar_venta_ok: "Ha modificado el estado del animal correctamente.",
      anuncio: "anuncio",
      nombre_explotacion: "Nombre explotación",
      direccion_explotacion: "Dirección explotacion",
      explotacion: "Explotacion",
      fecha_nacimiento: "Fecha nacimiento",
      numero_contrato: "Número contrato",
      sacrificio: "Sacrificio animal",
      fecha_sacrificio: "Fecha de sacrificio",
      matadero: "Matadero",
      sacrificado: "Sacrificado",
      entidad_calificadora: "Entidad calificadora",
      peso_canal: "Peso en canal",
      calidad_carne_matadero: "Calidad del animal para el matadero",
      image: "Animal image",
    },
    login: {
      error_credenciales: "Please check that the credentials are correct",
      usuario: "User...",
      contrasenya: "Password...",
    },
    estado_contrato: {
      0: "estado desconocido",
      1: "nuevo",
      2: "propuesto al ganadero",
      3: "firmado por el ganadero",
      4: "firmado por ambas partes",
      5: "propuesto para finalizacion por ganadero",
      6: "propuesta de finalizacion aceptada por ganadero",
      7: "finalizado consensuadamente",
      8: "propuesto para finalizacion  por operador",
      9: "propuesta de finalizacion aceptada por operador",
      10: "rescindido",
      11: "rechazado",
      12: "bloqueado",
      13: "finalizado y registrado en AICA",
      14: "finalizado con errores en registro AICA",
    },
    estado_animales: {
      1: "en explotacion",
      2: "en venta",
      3: "propuesto para contrato",
      4: "en contrato",
      5: "pendiente de salir de contrato",
      6: "sacrificado",
      7: "propuesto para sacrificio",
      8: "baja",
    },
    razas: {
      1: "Asturiana de los valles",
      2: "Asturiana de la montaña",
      3: "Conjunto mestizo",
      4: "Frisona",
      5: "Parda",
      6: "Parda de montaña",
      7: "Limusina",
    },
    codigos_error: {
      0: "Error en la operación",
      401: "Acceso denegado",
      4041: "No existe ningún operador asociado al usuario: {username}",
      4042: "No existe ningún contrato asociado a este operador: {username}",
      4001: "El animal con identificador: animalIS no está asociado al operador:{username}",
      4043: "El contrato no está asociado a este operador: {username}",
      4044: "No existe ninguna explotación asociada al identificador proporcionado: explotacionId",
      4045: "No existe ningún técnico asociado al identificador proporcionado: tecnicoId",
      4046: "No existe ninguna plantilla asociada al identificador proporcionado: {id}",
      4002: "El contrato contractName no tiene ningún fichero asociado",
      4047: "No existe un matadero con un identificador igual al proporcionado: mataderoId",
      4003: "El operador: {username}  no es el dueño del contrato proporcionado: contractName",
      4004: "Los campos tipo_venta y valoracion deben estar informados en un animal para poder ponerlo a la venta",
      4005: "El ganadero: {username} no tiene asociada ninguna explotación en el sistema",
      4006: "La explotación proporcionada para el animal no pertenece al ganadero",
      4007: "Ya existe un animal con el crotal proporcionado: {crotal}",
      4048: "No existe ningún animal con el identificador proporcionado:{id}",
      4049: "No existe ningún animal con el identificador proporcionado: animaId  perteneciente al ganadero: {username}",
      40410:
        "No existe ningún contrato con el identificador proporcionado contractId asociado a las explotaciones de este ganadero",
      4008: "No existe ningún usuario con el ID asociado al operador para este contrato",
      4009: "No existe el operador asociado a este contrato",
      40010:
        "La explotación asociada al contrato: {contractName} no pertenece al ganadero",
      40411: "No existe ningún ganadero asociado al usuario: {username}",
      40012:
        "No existe ninguna explotación con el identificador proporcionado en el sistema para este ganadero",
      40013:
        "El identificador del ganadero asociado a la explotación no coincide con el identificador del ganadero que está realizando la petición",
      40014:
        "El identificador de la explotación asociado a la explotación en el cuerpo de la petición no coincide con el identificador de la explotación proporcionado en la URI de la petición",
      40015:
        "No se encontró ningún documento con signaturit documento ID: {id}",
      40016: "No se encontró ningún contrato asociado al fichero: {id}",
      40017:
        "El estado del contrato es inconsistente con la firma completada en signaturit: {contractState}",
      40412: "No existe ningún técnico asociado al usuario: {username}",
      40413:
        "No existe ningún contrato asociado a este matadero: {mataderoName}",
      40018: "El usuario: {username}  no tiene un matadero asociado",
      40019:
        "El animal con ID: {id} no pertenece a un contrato asociado al matadero",
      40414: "No existe ningún contrato asociado a este técnico: {tecnicoName}",
      40020: "El animal:{id} no está asociado al técnico: {tecnicoName}",
      40021:
        "No se puede pasar un animal de un estado: currentState a un estado: {state}",
      40022:
        "No se puede llevar a cabo la acción para un contrato en estado: {state}",
      40415:
        "No existe ningún contrato con el identificador proporcionado: {id}",
      40023:
        "El contrato ya tiene el número máximo de animales asignados: {maxNumber}",
      40416:
        "No se encontró ningún fichero en la base de datos para el ID:{id}",
    },
    dialogs: {
      rescindContract:
        'Esta acción implica que se "Rescinde" el contrato unilateralmente, eso podrá tener implicaciones jurídicas si la otra parte no está de acuerdo. Con esta acción el contrato se cerrará, y todos los animales que estuviesen pendientes de entrega se desasignarán automáticamente del contrato. ¿Esta de acuerdo en cursar esa petición?',
      rejectContract:
        'Esta acción implica que se "Rechaza" el contrato ¿Esta de acuerdo en cursar esa petición?',
      endContract:
        'Esta acción enviará a la otra parte una petición para "Finalizar" el contrato. Esto significa que si la otra parte acepta la petición no se podrán asignar mas animales a este contrato en el futuro. Los animales incluidos en el contrato que estén pendientes de entrega se regirán por las condiciones acordadas en el contrato. ¿Esta de acuerdo en cursar esa petición?',
      removeContract:
        "Esta acción implica eliminar el contrato seleccionado. ¿Esta de acuerdo en cursar esa petición?",
      gestionarSalidaDeAnimal:
        'Esta acción implica que se "Desasigna" el animal del contrato. Con esta acción sen enviará una petición al operador para desasignar el animal del contrato. ¿Esta de acuerdo en cursar esa petición?',
      btnCancel: "Cancelar",
      btnConfirm: "Confirmar",
      btnDiscard: "Descartar",
    },
  },
};
