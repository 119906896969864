import { defineStore } from "pinia";
import { ref } from "vue";

import {
  IframeSignaturit,
  OfflineContract,
  OfflineRancher,
} from "@/types";

export const useOfflineContractStore = defineStore(
  "offlineContractStore",
  () => {
    // State
    const selectedOfflineContract = ref<OfflineContract>({} as OfflineContract);
    const showOfflineContractDetails = ref(false);
    const showOfflineContractForm = ref(false);
    const toggleLoadingOfflineContracts = ref(false);
    const toggleLoadingOfflineRanchers = ref(false);
    const offlineContracts = ref<OfflineContract[]>([]);
    const offlineRanchers = ref<OfflineRancher[]>([]);
    const offlineIframe = ref<IframeSignaturit>({} as IframeSignaturit);
    const offlineSignRole = ref("");
    const selectedNewOfflineRancher = ref<OfflineRancher>({} as OfflineRancher);
    const offlineContractAnimalList = ref<any[]>([]);
    const offlineRanchersSearchQuery = ref("");
    const offlineContractsSearchQuery = ref("");

    // Actions
    const openOfflineContractDetails = (contract: OfflineContract) => {
      selectedOfflineContract.value = contract;
      showOfflineContractDetails.value = true;
    };

    const toggleOfflineContractDetails = () => {
      showOfflineContractDetails.value = !showOfflineContractDetails.value;
    };

    const hideOfflineContractDetails = () => {
      showOfflineContractDetails.value = false;
    };

    const openOfflineContractForm = () => {
      showOfflineContractForm.value = true;
    };

    const hideOfflineContractForm = () => {
      showOfflineContractForm.value = false;
    };

    const toggleLoadOfflineContracts = () => {
      toggleLoadingOfflineContracts.value =
        !toggleLoadingOfflineContracts.value;
    };

    const setOfflineContracts = (newContracts: OfflineContract[]) => {
      offlineContracts.value = newContracts;
      if (selectedOfflineContract.value) {
        const refreshedContract = offlineContracts.value.find(
          (contract) => contract.id === selectedOfflineContract.value.id,
        );
        if (refreshedContract) {
          selectedOfflineContract.value = refreshedContract;
        }
      }
    };

    const toggleLoadOfflineRanchers = () => {
      toggleLoadingOfflineRanchers.value = !toggleLoadingOfflineRanchers.value;
    };

    const addNewOfflineAnimal = (animal: any) => {
      offlineContractAnimalList.value.push({ ...animal });
    };

    const resetNewOfflineContractForm = () => {
      selectedNewOfflineRancher.value = {} as OfflineRancher;
      offlineContractAnimalList.value = [];
    };

    const signOfflineContractRancher = (contractId: number) => {
      offlineSignRole.value = "GANADERO";
      offlineIframe.value.contractId = contractId;
      offlineIframe.value.open = true;
    };

    const signOfflineContractOperator = (contractId: number) => {
      offlineSignRole.value = "OPERADOR";
      offlineIframe.value.contractId = contractId;
      offlineIframe.value.open = true;
    };

    // Reset function
    const $reset = () => {
      selectedOfflineContract.value = {} as OfflineContract;
      showOfflineContractDetails.value = false;
      showOfflineContractForm.value = false;
      toggleLoadingOfflineContracts.value = false;
      toggleLoadingOfflineRanchers.value = false;
      offlineContracts.value = [];
      offlineRanchers.value = [];
      offlineIframe.value = {} as IframeSignaturit;
      offlineSignRole.value = "";
      selectedNewOfflineRancher.value = {} as OfflineRancher;
      offlineContractAnimalList.value = [];
      offlineRanchersSearchQuery.value = "";
      offlineContractsSearchQuery.value = "";
    };

    return {
      // State
      selectedOfflineContract,
      showOfflineContractDetails,
      showOfflineContractForm,
      toggleLoadingOfflineContracts,
      toggleLoadingOfflineRanchers,
      offlineContracts,
      offlineRanchers,
      offlineIframe,
      offlineSignRole,
      selectedNewOfflineRancher,
      offlineContractAnimalList,
      offlineRanchersSearchQuery,
      offlineContractsSearchQuery,
      // Actions
      openOfflineContractDetails,
      toggleOfflineContractDetails,
      hideOfflineContractDetails,
      openOfflineContractForm,
      hideOfflineContractForm,
      toggleLoadOfflineContracts,
      setOfflineContracts,
      toggleLoadOfflineRanchers,
      addNewOfflineAnimal,
      resetNewOfflineContractForm,
      signOfflineContractRancher,
      signOfflineContractOperator,
      // Reset
      $reset,
    };
  },
  {
    persist: true,
  },
);
