import { RouteRecordRaw } from "vue-router";

const Login = () => import("../views/public/pages/Login.vue");
const Logout = () => import("../views/public/pages/Logout.vue");
const RegisterChoose = () => import("../views/public/pages/RegisterChoose.vue");
const RegisterUser = () => import("../views/public/pages/RegisterUser.vue");
const RegisterCompanyForm = () =>
  import("../views/public/pages/RegisterCompanyForm.vue");
const RegisterRancher = () =>
  import("../views/public/pages/RancherRegister.vue");
const RegisterRancherEnterprise = () =>
  import("../views/public/pages/RancherRegisterEnterprise.vue");
const RegisterOperator = () =>
  import("../views/public/pages/OperatorRegister.vue");
const RegisterSuccess = () =>
  import("../views/public/pages/RegisterSuccess.vue");
const AccountValidation = () =>
  import("../views/public/pages/AccountValidation.vue");
const TermsAndConditions = () =>
  import("../views/public/pages/TermsAndConditions.vue");
const PrivacyPolicy = () => import("../views/public/pages/PrivacyPolicy.vue");
const PublicQr = () => import("../views/public/pages/PublicQr.vue");
const PublicAdvertView = () =>
  import("../views/public/pages/PublicAdvertView.vue");

const publicRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/:lang",
    component: Login,
  },
  {
    path: "/:lang/login",
    name: "login",
    component: Login,
  },
  {
    path: "/:lang/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/:lang/register/choose",
    name: "registerChoose",
    component: RegisterChoose,
  },
  {
    path: "/:lang/register/user",
    name: "registerUser",
    component: RegisterUser,
  },
  {
    path: "/:lang/register/company",
    name: "registerCompany",
    component: RegisterCompanyForm,
  },
  {
    path: "/:lang/register/rancher",
    name: "registerRancher",
    component: RegisterRancher,
  },
  {
    path: "/:lang/register/rancherEnterprise",
    name: "registerRancherEnterprise",
    component: RegisterRancherEnterprise,
  },
  {
    path: "/:lang/register/operator",
    name: "register/operator",
    component: RegisterOperator,
  },
  {
    path: "/:lang/register/success",
    name: "registerSuccess",
    component: RegisterSuccess,
  },
  {
    path: "/:lang/termsAndConditions",
    name: "termsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: "/:lang/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/:lang/validate/:token",
    name: "validate",
    component: AccountValidation,
  },
  {
    path: "/:lang/public/qr/:animalId",
    name: "public-qr",
    component: PublicQr,
    props: true,
  },
  {
    path: "/:lang/public/feria/:advertId",
    name: "public-feria",
    component: PublicAdvertView,
    props: true,
  },
];

export default publicRoutes;
