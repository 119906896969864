import { RouteRecordRaw } from "vue-router";

const TabsGanadero = () => import("../views/ganadero/TabsGanadero.vue");
const AnimalesGanadero = () => import("../views/ganadero/Animales.vue");
const NewAnimalRancherView = () => import("../views/ganadero/NewAnimal.vue");
const NewAnimalListRancherView = () =>
  import("../views/ganadero/NewAnimalList.vue");
const AnimalGanadero = () => import("../views/ganadero/Animal.vue");
const ContratosGanadero = () => import("../views/ganadero/Contratos.vue");
const ContratoGanadero = () => import("../views/ganadero/Contrato.vue");
const ContratoCompraGanadero = () =>
  import("../views/ganadero/ContratoCompra.vue");
const PerfilGanadero = () => import("../views/ganadero/Perfil.vue");
const MisExplotacionesGanadero = () =>
  import("../views/ganadero/MisExplotaciones.vue");
const ConfiguracionGanadero = () =>
  import("../views/ganadero/Configuracion.vue");
const FeriaVirtualGanadero = () =>
  import("../views/feria_virtual/FeriaVirtual.vue");
const NewAdvertRancherSwipe = () =>
  import("../views/feria_virtual/NewAdvertRancher.vue");
const MyAdvertRancher = () => import("../views/feria_virtual/MyAdvert.vue");
const AdvertiserProfileRancher = () =>
  import("../views/feria_virtual/AdvertiserProfile.vue");
const MyAdvertsRancher = () => import("../views/feria_virtual/MyAdverts.vue");
const AdvertProposalViewRancher = () =>
  import("../views/feria_virtual/AdvertProposalView.vue");
const Contact = () => import("../views/ContactPage.vue");
const Support = () => import("../views/SupportPage.vue");
const NotificationsPage = () => import("../views/NotificationsPage.vue");
const TemplatesView = () => import("../views/templates/Templates.vue");
const Rates = () => import("../views/rates/Rates.vue");
const RatesCrud = () => import("../views/rates/RatesCrud.vue");
const NewContract = () => import("../views/NewContract.vue");
const MyAdvertProposals = () =>
  import("../views/feria_virtual/MyAdvertProposals.vue");
const AdvertView = () => import("../views/feria_virtual/AdvertView.vue");
const OfflineContracts = () => import("../views/offline/OfflineContracts.vue");
const NewOfflineContract = () =>
  import("../views/offline/NewOfflineContract.vue");
const OfflineContract = () => import("../views/offline/OfflineContract.vue");
const OfflineRanchers = () => import("../views/offline/OfflineRanchers.vue");
const OfflineRanchersCrud = () =>
  import("../views/offline/OfflineRanchersCrud.vue");

const ganaderoRoutes: RouteRecordRaw[] = [
  {
    path: "/:lang/ganadero",
    component: TabsGanadero,
    meta: { requiresAuth: true },
    children: [
      {
        path: "animales",
        redirect: "/es/ganadero/animales/en-propiedad",
      },
      {
        path: "animales/:segment",
        name: "animales-ganadero",
        component: AnimalesGanadero,
        props: (route) => ({
          segment: route.params.segment ? route.params.segment : "en-propiedad",
        }),
      },
      {
        path: "animales/nuevo",
        name: "nuevo-animal-ganadero",
        component: NewAnimalRancherView,
      },
      {
        path: "animales/nuevo-listado",
        name: "nuevo-listado-ganadero",
        component: NewAnimalListRancherView,
      },
      {
        path: "animales/animal/:animalId",
        name: "animal-ganadero",
        component: AnimalGanadero,
        props: true,
      },
      {
        path: "contratos",
        redirect: "/es/ganadero/contratos/venta",
      },
      {
        path: "contratos/:segment",
        name: "contratos-ganadero",
        component: ContratosGanadero,
        props: (route) => ({
          segment: route.params.segment ? route.params.segment : "venta",
        }),
      },
      {
        path: "contratos/contrato/:contractId",
        name: "contrato-ganadero",
        component: ContratoGanadero,
        props: true,
      },
      {
        path: "contratos/compra/contrato/:contractId",
        name: "contrato-compra-ganadero",
        component: ContratoCompraGanadero,
        props: true,
      },
      {
        path: "contratos/nuevo",
        name: "nuevo-contrato-ganadero",
        component: NewContract,
      },
      {
        path: "contratos/templates",
        name: "templates-ganadero",
        component: TemplatesView,
      },
      {
        path: "contratos/rates",
        name: "rates-ganadero",
        component: Rates,
      },
      {
        path: "contratos/rates/crud/:rateId?",
        name: "rates-crud-ganadero",
        props: (route) => ({
          rateId: route.params.rateId ? route.params.rateId : undefined,
        }),
        component: RatesCrud,
      },
      {
        path: "contratos/offline",
        name: "offline-contracts-rancher",
        component: OfflineContracts,
      },
      {
        path: "contratos/offline/new",
        name: "new-offline-contract-rancher",
        component: NewOfflineContract,
      },
      {
        path: "contratos/offline/:contractId",
        name: "offline-contract-rancher",
        props: (route) => ({
          contractId: route.params.contractId
            ? route.params.contractId
            : undefined,
        }),
        component: OfflineContract,
      },
      {
        path: "contratos/offline/ranchers",
        name: "offline-ranchers-rancher",
        component: OfflineRanchers,
      },
      {
        path: "contratos/offline/ranchers/crud/:rancherId?",
        name: "offline-ranchers-crud-rancher",
        props: (route) => ({
          rancherId: route.params.rancherId
            ? route.params.rancherId
            : undefined,
        }),
        component: OfflineRanchersCrud,
      },
      {
        path: "notifications",
        name: "notifications-ganadero",
        component: NotificationsPage,
      },
      {
        path: "perfil",
        name: "perfil-ganadero",
        component: PerfilGanadero,
      },
      {
        path: "mis-explotaciones",
        name: "mis-explotaciones-ganadero",
        component: MisExplotacionesGanadero,
      },
      {
        path: "configuracion",
        name: "configuracion-ganadero",
        component: ConfiguracionGanadero,
      },
      {
        path: "feria",
        name: "feria-virtual-ganadero",
        component: FeriaVirtualGanadero,
      },
      {
        path: "feria/nuevo-anuncio",
        name: "nuevo-anuncio-ganadero",
        component: NewAdvertRancherSwipe,
      },
      {
        path: "feria/anuncio/:advertId",
        name: "vista-anuncio-ganadero",
        component: AdvertView,
        props: true,
      },
      {
        path: "feria/mis-anuncios",
        name: "mis-anuncios-ganadero",
        component: MyAdvertsRancher,
      },
      {
        path: "feria/mi-anuncio/:advertId/:segment?",
        name: "vista-mi-anuncio-ganadero",
        component: MyAdvertRancher,
        props: (route) => ({
          advertId: route.params.advertId,
          segment: route.params.segment ? route.params.segment : undefined,
        }),
      },
      {
        path: "feria/anunciante/:userId",
        name: "vista-anunciante-ganadero",
        component: AdvertiserProfileRancher,
        props: true,
      },
      {
        path: "feria/propuestas/mis-propuestas",
        name: "mis-propuestas-ganadero",
        component: MyAdvertProposals,
      },
      {
        path: "feria/propuestas/:advertProposalId",
        name: "vista-propuesta-ganadero",
        component: AdvertProposalViewRancher,
        props: true,
      },
      {
        path: "contact",
        name: "contact-ganadero",
        component: Contact,
      },
      {
        path: "support",
        name: "support-ganadero",
        component: Support,
      },
    ],
  },
];

export default ganaderoRoutes;
