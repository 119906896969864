import { App, URLOpenListenerEvent } from "@capacitor/app";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

import { clearAllModals } from "@/composables/useModalBackButton";
import { useNotificationsUnreadCount } from "@/composables/useNotificationsUnreadCount";

import ganaderoRoutes from "./router/ganaderoRoutes";
import operadorRoutes from "./router/operadorRoutes";
import publicRoutes from "./router/publicRoutes";
import { useAuthStore } from "./stores/AuthStore";
import { useUserStore } from "./stores/UserStore";

const routes: Array<RouteRecordRaw> = [
  ...publicRoutes,
  ...ganaderoRoutes,
  ...operadorRoutes,

  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;

const notificationsScope = effectScope();

router.beforeEach((to, from, next) => {
  const authRequiredPaths = ["/operador", "/ganadero", "/technician"];
  const isAuthRequiredPath = authRequiredPaths.some((path) =>
    to.path.includes(path),
  );

  const authStore = useAuthStore();
  const userStore = useUserStore();

  const loginPaths = ["/", "/es", "/es/login"];
  const isLoginPath = loginPaths.some((path) => to.path === path);

  if (authStore.isLoggedIn && isLoginPath) {
    const rolePaths = {
      operador: "/es/operador/animales",
      ganadero: "/es/ganadero/animales",
      empresa_ganadera: "/es/ganadero/animales",
      technician: "/es/technician/contratos",
      partial: "/es/ganadero/animales",
    };

    let redirectPath = null;
    if (userStore.user.role)
      redirectPath =
        rolePaths[userStore.user.role.toLowerCase()] || "/es/login";

    if (to.path !== redirectPath) {
      next(redirectPath);
    } else {
      next();
    }
  } else if (isAuthRequiredPath && !authStore.isLoggedIn) {
    if (to.path !== "/es/login") {
      next("/es/login");
    } else {
      next();
    }
  } else {
    if (authStore.isLoggedIn) {
      // Only run once when logged in, not on every navigation
      notificationsScope.run(() => {
        useNotificationsUnreadCount()
          .refetchUnreadCount()
          .catch((error) => {
            console.error("Failed to fetch notifications count:", error);
          });
      });
    }
    next();
  }
});

// Clean up scope when app is destroyed
router.onError(() => {
  notificationsScope.stop();
});

App.addListener("appUrlOpen", async (event: URLOpenListenerEvent) => {
  try {
    const url = new URL(event.url);
    const slug = url.pathname;

    if (slug && slug.length > 1) {
      await clearAllModals();

      // Wait for preventing redirections on modal closing
      setTimeout(async () => {
        await router.push({
          path: slug,
          replace: true,
          state: { deepLink: true },
        });
      }, 200);
    }
  } catch (error) {
    console.error("Deep link handling failed:", error);
    await router.push("/");
  }
});
