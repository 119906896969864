import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { defaultContractsFilter } from "@/constants/defaults";
import apiService from "@/core/services/api.service";
import {
  Contract,
  ContractsFilter,
  ContractTable,
  IframeSignaturit,
  RanchContract,
} from "@/types";

import { useAnimalRancherStore } from "./AnimalRancherStore";

export const useContractRancherStore = defineStore(
  "contractRancherStore",
  () => {
    // State as refs
    const selectedContract = ref<ContractTable>({} as ContractTable);
    const showContractDetails = ref(false);
    const showAddAnimalsToContract = ref(false);
    const toggleLoadingContracts = ref(false);
    const contracts = ref<Contract[]>([]);
    const buyerContracts = ref<Contract[]>([]);
    const iframe = ref<IframeSignaturit>({} as IframeSignaturit);
    const iframeFinish = ref<IframeSignaturit>({} as IframeSignaturit);
    const allRanches = ref<RanchContract[]>([]);
    const showContractForm = ref(false);
    const showContractsFilter = ref(false);
    const sortColumn = ref("number");
    const sortOrder = ref("desc");
    const sortOrderOptions = ref([
      { label: "Ascendente", value: "asc" },
      { label: "Descendente", value: "desc" },
    ]);
    const sortOptions = ref([
      { label: "Número de Contrato", value: "number" },
      { label: "Nombre", value: "name" },
      { label: "Estado", value: "state.name" },
      { label: "Número de Animales", value: "numberOfAnimals" },
      { label: "Animales Pendientes", value: "pendingAnimals" },
      { label: "Peticiones Pendientes", value: "pendingPetitions" },
      { label: "Explotación", value: "ranch.name" },
    ]);
    const contractsFilter = ref(defaultContractsFilter);

    // Computed properties (converted from getters)
    const ranchesOptions = computed(() => {
      if (Array.isArray(allRanches.value) && allRanches.value.length > 0) {
        return allRanches.value.map(({ id, nombre_casa, cea }) => ({
          value: id,
          label: nombre_casa + " - " + cea,
        }));
      }
      return [{ value: 0, label: "No hay resultados" }];
    });

    // Actions as functions
    const openAddAnimalsToContract = (contract: ContractTable) => {
      selectedContract.value = contract;
      showAddAnimalsToContract.value = true;
    };

    const hideAddAnimalsToContract = () => {
      showAddAnimalsToContract.value = false;
    };

    const openContractDetails = (contract: ContractTable) => {
      const animalRancherStore = useAnimalRancherStore();
      animalRancherStore.hideAnimalDetails();
      selectedContract.value = contract;
      showContractDetails.value = true;
    };

    const toggleContractDetails = () => {
      const animalRancherStore = useAnimalRancherStore();
      animalRancherStore.hideAnimalDetails();
      showContractDetails.value = !showContractDetails.value;
    };

    const hideContractDetails = () => {
      showContractDetails.value = false;
    };

    const toggleLoadContracts = () => {
      toggleLoadingContracts.value = !toggleLoadingContracts.value;
    };

    const fetchAllOtherRanches = async () => {
      const response = await apiService.get(
        "/ganadero/explotaciones/all-except-mine",
      );
      allRanches.value = response.data.content;
    };

    const fetchAllOtherRanchesList = async () => {
      const response = await apiService.get(
        "/ganadero/explotaciones/all-except-mine-list",
      );
      allRanches.value = response.data;
    };

    const openContractForm = () => {
      showContractForm.value = true;
    };

    const hideContractForm = () => {
      showContractForm.value = false;
    };

    const openContractsFilter = () => {
      showContractsFilter.value = true;
    };

    const hideContractsFilter = () => {
      showContractsFilter.value = false;
    };

    const toggleSortOrder = () => {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
    };

    const cleanContractsFilter = () => {
      contractsFilter.value = defaultContractsFilter;
      sortColumn.value = "number";
      sortOrder.value = "desc";
    };

    const setContractsFilter = (filter: ContractsFilter) => {
      contractsFilter.value = filter;
    };

    // Reset function
    const $reset = () => {
      selectedContract.value = {} as ContractTable;
      showContractDetails.value = false;
      showAddAnimalsToContract.value = false;
      toggleLoadingContracts.value = false;
      contracts.value = [];
      buyerContracts.value = [];
      iframe.value = {} as IframeSignaturit;
      iframeFinish.value = {} as IframeSignaturit;
      allRanches.value = [];
      showContractForm.value = false;
      showContractsFilter.value = false;
      sortColumn.value = "number";
      sortOrder.value = "desc";
      contractsFilter.value = defaultContractsFilter;
    };

    return {
      // State
      selectedContract,
      showContractDetails,
      showAddAnimalsToContract,
      toggleLoadingContracts,
      contracts,
      buyerContracts,
      iframe,
      iframeFinish,
      allRanches,
      showContractForm,
      showContractsFilter,
      sortColumn,
      sortOrder,
      sortOrderOptions,
      sortOptions,
      contractsFilter,
      ranchesOptions,
      // Actions
      openAddAnimalsToContract,
      hideAddAnimalsToContract,
      openContractDetails,
      toggleContractDetails,
      hideContractDetails,
      toggleLoadContracts,
      fetchAllOtherRanches,
      fetchAllOtherRanchesList,
      openContractForm,
      hideContractForm,
      openContractsFilter,
      hideContractsFilter,
      toggleSortOrder,
      cleanContractsFilter,
      setContractsFilter,
      $reset,
    };
  },
  {
    persist: true,
  },
);
