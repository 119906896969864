import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { TemplateConstants } from "@/constants/constants";
import apiService from "@/core/services/api.service";
import { Template } from "@/types";

// Function to return the initial state
const getDefaultState = () => ({
  templates: [] as Template[],
  toggleLoadingTemplates: false,
});

export const useTemplateStore = defineStore(
  "templateStore",
  () => {
    const templates = ref<Template[]>([]);
    const toggleLoadingTemplates = ref(false);

    // Actions
    const fetchTemplates = async () => {
      try {
        const response = await apiService.get("/plantillas");
        templates.value = response.data;
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    const checkAndFetchTemplates = async () => {
      if (templates.value.length === 0) {
        await fetchTemplates();
      }
    };

    const setTemplates = (newTemplates: Template[]) => {
      templates.value = newTemplates;
    };

    const toggleLoadTemplates = () => {
      toggleLoadingTemplates.value = !toggleLoadingTemplates.value;
    };

    // Getters
    const templatesOptions = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value.map((template: Template) => ({
          value: template.id,
          label: template.nombre,
        }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasTemplates = computed(() => {
      return templates.value.length > 0;
    });

    const templatesOptionsFixedPrice = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.tipo_precio === TemplateConstants.PriceType.FIXED,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasFixedPriceTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.tipo_precio === TemplateConstants.PriceType.FIXED,
      );
    });

    const templatesOptionsRate = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.tipo_precio === TemplateConstants.PriceType.RATE,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasRateTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.tipo_precio === TemplateConstants.PriceType.RATE,
      );
    });

    const templatesOptionsProvisioning = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.context === TemplateConstants.Context.PROVISIONING,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasProvisioningTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.context === TemplateConstants.Context.PROVISIONING,
      );
    });

    const templatesOptionsProvisioningFixedPrice = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.tipo_precio === TemplateConstants.PriceType.FIXED &&
              template.context === TemplateConstants.Context.PROVISIONING,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasProvisioningFixedPriceTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.tipo_precio === TemplateConstants.PriceType.FIXED &&
          template.context === TemplateConstants.Context.PROVISIONING,
      );
    });

    const templatesOptionsProvisioningRate = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.tipo_precio === TemplateConstants.PriceType.RATE &&
              template.context === TemplateConstants.Context.PROVISIONING,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasProvisioningRateTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.tipo_precio === TemplateConstants.PriceType.RATE &&
          template.context === TemplateConstants.Context.PROVISIONING,
      );
    });

    const templatesOptionsOffline = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.context === TemplateConstants.Context.OFFLINE,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasOfflineTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.context === TemplateConstants.Context.OFFLINE,
      );
    });

    const templatesOptionsFeria = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.context === TemplateConstants.Context.FERIA,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasFeriaTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.context === TemplateConstants.Context.FERIA,
      );
    });

    const templatesOptionsFeriaFixedPrice = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.tipo_precio === TemplateConstants.PriceType.FIXED &&
              template.context === TemplateConstants.Context.FERIA,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasFeriaFixedPriceTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.tipo_precio === TemplateConstants.PriceType.FIXED &&
          template.context === TemplateConstants.Context.FERIA,
      );
    });

    const templatesOptionsFeriaRate = computed(() => {
      if (Array.isArray(templates.value) && templates.value.length > 0) {
        return templates.value
          .filter(
            (template: Template) =>
              template.tipo_precio === TemplateConstants.PriceType.RATE &&
              template.context === TemplateConstants.Context.FERIA,
          )
          .map((template: Template) => ({
            value: template.id,
            label: template.nombre,
          }));
      } else {
        return [{ value: 0, label: "No hay resultados" }];
      }
    });

    const hasFeriaRateTemplates = computed(() => {
      return templates.value.some(
        (template: Template) =>
          template.tipo_precio === TemplateConstants.PriceType.RATE &&
          template.context === TemplateConstants.Context.FERIA,
      );
    });

    // Method to reset the store's state
    const $reset = () => {
      templates.value = getDefaultState().templates;
      toggleLoadingTemplates.value = getDefaultState().toggleLoadingTemplates;
    };

    return {
      templates,
      toggleLoadingTemplates,
      fetchTemplates,
      checkAndFetchTemplates,
      setTemplates,
      toggleLoadTemplates,
      templatesOptions,
      templatesOptionsFixedPrice,
      templatesOptionsRate,
      templatesOptionsProvisioning,
      templatesOptionsProvisioningFixedPrice,
      templatesOptionsProvisioningRate,
      templatesOptionsOffline,
      templatesOptionsFeria,
      templatesOptionsFeriaFixedPrice,
      templatesOptionsFeriaRate,
      hasFixedPriceTemplates,
      hasRateTemplates,
      hasProvisioningTemplates,
      hasProvisioningFixedPriceTemplates,
      hasProvisioningRateTemplates,
      hasOfflineTemplates,
      hasFeriaTemplates,
      hasFeriaFixedPriceTemplates,
      hasFeriaRateTemplates,
      hasTemplates,
      $reset,
    };
  },
  {
    persist: true,
  },
);
