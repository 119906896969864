import type { Page } from "@/core/models/Page";
import apiService from "@/core/services/api.service";
import type { NotificationDTO } from "@/types";

export const notificationsApi = {
  async getUserNotifications(params: {
    page: number;
    pageSize: number;
  }): Promise<Page<NotificationDTO[]>> {
    const response = await apiService.get("/notifications", {
      page: params.page,
      size: params.pageSize,
    });
    return response.data;
  },

  async getUnreadCount(): Promise<number> {
    const response = await apiService.get("/notifications/count");
    return response.data;
  },

  async markAsRead(id: number): Promise<void> {
    await apiService.put(`/notifications/${id}/read`, {});
  },

  async markAllAsRead(): Promise<void> {
    await apiService.put("/notifications/read-all", {});
  },

  async deleteNotification(id: number): Promise<void> {
    await apiService.delete(`/notifications/${id}`, null);
  },

  async deleteAllNotifications(): Promise<void> {
    await apiService.delete("/notifications", null);
  },
};
